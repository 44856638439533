import { Outlet } from "react-router";
import styles from "../Layout.less";

export default () => {
  return (
    <div className={styles.content}>
      <Outlet />
    </div>
  );
};
