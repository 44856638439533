const map: Map<string, any> = new Map();

const getInstance = <T>(key: string, cb?: () => T): T => {
	if (map.has(key)) {
		return map.get(key) as T;
	} else if (cb) {
		const result = cb();
		map.set(key, result);
		return result;
	} else {
		console.error("please check if Target Object is Created");
		return "" as unknown as T;
	}
};

export { getInstance };
