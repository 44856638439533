export const keys = {
  ContinueWithGoogle: "ContinueWithGoogle",
  ContinueWithEmail: "ContinueWithEmail",
  TapToAgree: "TapToAgree",
  TapToTermsOfService: "TapToTermsOfService",
  And: "And",
  TapToPrivatePolicy: "TapToPrivatePolicy",
  InputEmail: "InputEmail",
  EmailAddress: "EmailAddress",
  VerificationCode: "VerificationCode",
  SendVerificationCode: "SendVerificationCode",
  ResendVerificationCode: "ResendVerificationCode",
  LogIn: "LogIn",
  InvalidEmail: "InvalidEmail",
  InvalidVerificationCode: "InvalidVerificationCode",
  VerificationCodeExpired: "VerificationCodeExpired",
  Offers: "Offers",
  Order: "Order",
  Profile: "Profile",
  Network: "Network",
  Amount: "Amount",
  All: "All",
  PaymentMethod: "PaymentMethod",
  SortBy: "SortBy",
  PriceASC: "PriceASC",
  LimitDESC: "LimitDESC",
  Reset: "Reset",
  Confirm: "Confirm",
  PayOrCancel: "PayOrCancel",
  ViewOrder: "ViewOrder",
  Terms: "Terms",
  Merchant: "Merchant",
  ReadBeforeOrder: "ReadBeforeOrder",
  Price: "Price",
  IWillPay: "IWillPay",
  IWillReceive: "IWillReceive",
  MyReceivingAddress: "MyReceivingAddress",
  InputMatchedAddress: "InputMatchedAddress",
  PaymentTimeLimit: "PaymentTimeLimit",
  Next: "Next",
  Margin: "Margin",
  KYCVerified: "KYCVerified",
  EmailVerified: "EmailVerified",
  SMSVerified: "SMSVerified",
  SelectPaymentMethod: "SelectPaymentMethod",
  ReceivingAddressBook: "ReceivingAddressBook",
  Add: "Add",
  Min: "Min",
  Max: "Max",
  AddressNotMatchNetwork: "AddressNotMatchNetwork",
  ContactMerchant: "ContactMerchant",
  PaymentAmount: "PaymentAmount",
  Quantity: "Quantity",
  SafetyReminder: "SafetyReminder",
  BeforePayNeedScreenshot: "BeforePayNeedScreenshot",
  AfterPayNeedClick: "AfterPayNeedClick",
  PayCountdown: "PayCountdown",
  HavingTrouble: "HavingTrouble",
  PaidAndNotifiy: "PaidAndNotifiy",
  OrderNotPay: "OrderNotPay",
  TroubleWithPayment: "TroubleWithPayment",
  NotWantToTransfer: "NotWantToTransfer",
  CancelOrderNotify: "CancelOrderNotify",
  PayFailed: "PayFailed",
  AskForOtherPayment: "AskForOtherPayment",
  CancelOrder: "CancelOrder",
  Warning: "Warning",
  PaidNotification: "PaidNotification",
  CancelOrderTip: "CancelOrderTip",
  TransactionRestrictionsTip: "TransactionRestrictionsTip",
  DoubleConfirmInCancel: "DoubleConfirmInCancel",
  PaymentMethodUpdated: "PaymentMethodUpdated",
  Refresh: "Refresh",
  WaitingForMerchantCheckReceive: "WaitingForMerchantCheckReceive",
  TransferToYourWallet: "TransferToYourWallet",
  TransferredToYourWallet: "TransferredToYourWallet",
  ViewOtherOffers: "ViewOtherOffers",
  Appeal: "Appeal",
  Reason: "Reason",
  PleaseSelect: "PleaseSelect",
  Description: "Description",
  PleaseInput: "PleaseInput",
  UploadEvidence: "UploadEvidence",
  Submit: "Submit",
  AppealReason: "AppealReason",
  PaidNotReceiveCoins: "PaidNotReceiveCoins",
  Others: "Others",
  ReceiveButAmountNotRight: "ReceiveButAmountNotRight",
  ReceiveButNotSecure: "ReceiveButNotSecure",
  NotReceiveFiat: "NotReceiveFiat",
  ExPalNotification: "ExPalNotification",
  Pending: "Pending",
  Completed: "Completed",
  Released: "Released",
  Received: "Received",
  WaitingForRelease: "WaitingForRelease",
  Pay: "Pay",
  ReleaseTimeout: "ReleaseTimeout",
  Cancelled: "Cancelled",
  Successful: "Successful",
  OrderNumber: "OrderNumber",
  CreationTime: "CreationTime",
  PaidWaitingForRelease: "PaidWaitingForRelease",
  MerchantReleased: "MerchantReleased",
  TransactionSuccessful: "TransactionSuccessful",
  CancellationReason: "CancellationReason",
  BuyerCancelled: "BuyerCancelled",
  MerchantCancelled: "MerchantCancelled",
  PlatformCancelled: "PlatformCancelled",
  ReleaseTimeoutTip: "ReleaseTimeoutTip",
  NoData: "NoData",
  TotalPurchases: "TotalPurchases",
  MyAppeals: "MyAppeals",
  HelpCenter: "HelpCenter",
  ConfirmToRemoveAddress: "ConfirmToRemoveAddress",
  Cancel: "Cancel",
  RemovedSuccessfully: "RemovedSuccessfully",
  AddAddress: "AddAddress",
  SelectNetwork: "SelectNetwork",
  Address: "Address",
  Alias: "Alias",
  CharactersLimit32Tip: "CharactersLimit32Tip",
  EditAlias: "EditAlias",
  Saved: "Saved",
  UnderAppeal: "UnderAppeal",
  AppealDetails: "AppealDetails",
  Resolution: "Resolution",
  AppealCancelled: "AppealCancelled",
  AppealProcessing: "AppealProcessing",
  Language: "Language",
  CheckForUpdates: "CheckForUpdates",
  AlreadyOnLatestVersion: "AlreadyOnLatestVersion",
  PrivatePolicy: "PrivatePolicy",
  LogOut: "LogOut",
  NewVersionFound: "NewVersionFound",
  UpdateNow: "UpdateNow",
  Inquiries: "Inquiries",
  BuyerFAQ: "BuyerFAQ",
  MerchantFAQ: "MerchantFAQ",
  YourName: "YourName",
  Message: "Message",
  SubmitSuccessfully: "SubmitSuccessfully",
  AppealRespondTip: "AppealRespondTip",
  OrderDetails: "OrderDetails",
  ReceivingAddress: "ReceivingAddress",
  Switch: "Switch",
  ConfirmRelease: "ConfirmRelease",
  ConfirmAsset: "ConfirmAsset",
  CheckReceiveAccount: "CheckReceiveAccount",
  EnsureToReceivePayment: "EnsureToReceivePayment",
  WaitPayment: "WaitPayment",
  WaitingPromptly: "WaitingPromptly",
  ReleaseAddress: "ReleaseAddress",
  EnsureAddress: "EnsureAddress",
  SwitchShop: "SwitchShop",
  TransFeeBalance: "TransFeeBalance",
  Bill: "Bill",
  TotalSales: "TotalSales",
  OffersManagement: "OffersManagement",
  PaymentMethodManagement: "PaymentMethodManagement",
  ReleaseAddressBook: "ReleaseAddressBook",
  TransactionFee: "TransactionFee",
  CumulativeDeposit: "CumulativeDeposit",
  CumulativeWithdrawal: "CumulativeWithdrawal",
  AllTypes: "AllTypes",
  Deposit: "Deposit",
  Withdrawal: "Withdrawal",
  UntilToday: "UntilToday",
  Recent30Days: "Recent30Days",
  InsuffcientBalanceWarn: "InsuffcientBalanceWarn",
  ArrearsWarn: "ArrearsWarn",
  ReceiveChannel: "ReceiveChannel",
  SelectReceiveChannel: "SelectReceiveChannel",
  ReceiveNotes: "ReceiveNotes",
  PleaseInputPaymentDetails: "PleaseInputPaymentDetails",
  Customize: "Customize",
  ReceiveChannelPlaceholder: "ReceiveChannelPlaceholder",
  AddSuccess: "AddSuccess",
  Save: "Save",
  SaveSuccess: "SaveSuccess",
  Deleted: "Deleted",
  CurrentPaymentHasBeenReferenced: "CurrentPaymentHasBeenReferenced",
  AddPaymentMethod: "AddPaymentMethod",
  EditPaymentMethod: "EditPaymentMethod",
  EditPaymentMethodWarn: "EditPaymentMethodWarn",
  Published: "Published",
  Unpublished: "Unpublished",
  SingleOrderLimitRange: "SingleOrderLimitRange",
  SelectPaymentMethodToReceive: "SelectPaymentMethodToReceive",
  Select: "Select",
  Delete: "Delete",
  SelectPaymentMethodMaxCountWarn: "SelectPaymentMethodMaxCountWarn",
  SelectPaymentMethodDuplicatedWarn: "SelectPaymentMethodDuplicatedWarn",
  SelectPaymentMethodMinCountWarn: "SelectPaymentMethodMinCountWarn",
  DeleteOfferFailWarn: "DeleteOfferFailWarn",
  CheckSelectedNetwork: "CheckSelectedNetwork",
  ScanQRCodeOrCopyWalletAddressTOTransfer: "ScanQRCodeOrCopyWalletAddressTOTransfer",
  WithDrawAll: "WithDrawAll",
  WithDrawAmount: "WithDrawAmount",
  ExpectedReceivedAmount: "ExpectedReceivedAmount",
  InputWalletAddress: "InputWalletAddress",
  ReleaseAddressMaxCountWarn: "ReleaseAddressMaxCountWarn",
  TheMaxLimitForSingleOrderInfo: "TheMaxLimitForSingleOrderInfo",
  BeforeOrderInfo: "BeforeOrderInfo",
  RemoveWarn: "RemoveWarn",
  Removed: "Removed",
  AddReleaseAddress: "AddReleaseAddress",
  PleaseInputCharacters: "PleaseInputCharacters",
  WaitingForBuyerPayment: "WaitingForBuyerPayment",
  MerchantReleasedTip: "MerchantReleasedTip",
  UrgeToTransfer: "UrgeToTransfer",
  copied: "copied",
  FaitChannel: "FaitChannel",
  LimitAmount: "LimitAmount",
  GasFeeTip: "GasFeeTip",
  WithdrawGasFeeTip: "WithdrawGasFeeTip",
  Failed: "Failed",
  reapplyForWithdraw: "reapplyForWithdraw",
  Withdrawing: "Withdrawing",
  NewOffer: "NewOffer",
  More: "More",
  EditOffer: "EditOffer",
  Common: "Common",
  Settings: "Settings",
  SelectLanguage: "SelectLanguage",
  ViewAll: "ViewAll",
  TransactionInformation: "TransactionInformation",
  OrderGenerated: "OrderGenerated",
  proof: "proof",
  balance: "balance",
  sendSuccessfully: "sendSuccessfully",
  codeSent: "codeSent",
  deletePaymentMethod: "deletePaymentMethod",
  deleteOffer: "deleteOffer",
  PaymentMethodShort: "PaymentMethodShort",
  Payment: "Payment",
  UnknownError: "UnknownError",
  Confirmed: "Confirmed",
  NotifiedTheMerchant: "NotifiedTheMerchant",
  Buyer: "Buyer",
  ContactBuyer: "ContactBuyer",
};
export type TranslationKeys = typeof keys;
export type KeyOfTranslationKeys = keyof TranslationKeys;
