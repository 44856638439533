//需求第三方库 i18next react-i18next
import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
//每一种语言对应一个ts文件
import { zh_CN } from "./languages/zh_CN";
import { en } from "./languages/en";
import { Platform } from "react-native";

//定义语言源
const resources = {
	en: {
		translation: en,
	},
	zh_CN: {
		translation: zh_CN,
	},
};

const isApp = ["android", "ios"].includes(Platform.OS);

const config: InitOptions = {
	resources,
	// 默认语言 系统语言 无法获取=>English
	lng: "en",
	interpolation: {
		escapeValue: false,
	},
};

if (isApp) {
	config.compatibilityJSON = "v3";
}

i18n.use(initReactI18next).init(config);
