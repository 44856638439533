import styles from "./Loading.less";
import { SpinLoading } from "antd-mobile";
import loading from "shared/assets/icon/loading.png";

export default function Loading(props: { special?: boolean }) {
	return (
		<div className={`base-msg ${styles["loading-container"]}`}>
			{props.special ? <img src={loading} /> : <SpinLoading />}
		</div>
	);
}
