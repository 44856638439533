import NavBar from "antd-mobile/es/components/nav-bar";
import { PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router";
import styles from "./NavContent.less";
import arrow from "shared/assets/icon/arrow.svg";
import { getLocalStorage } from "domain/shared/localStorage.ts/localStorage";

interface Props extends PropsWithChildren {
	title: string | ReactNode;
	right?: ReactNode;
	contentClass?: string;
	primary?: boolean;
	noPadding?: boolean;
	onNavClick?: VoidFunction;
	backIcon?: string;
}

export default ({ title, right, contentClass, children, primary, noPadding, onNavClick, backIcon }: Props) => {
	const navigation = useNavigate();
	return (
		<div
			className={styles.container}
			style={{ height: `calc(100vh - ${getLocalStorage("statusBarHeight") || 30}px)` }}
		>
			<NavBar
				onBack={() => (onNavClick ? onNavClick() : navigation(-1))}
				right={right}
				className={`${styles.nav} ${primary ? styles.primary : ""}`}
				backArrow={<img src={backIcon ? backIcon : arrow} />}
			>
				{title}
			</NavBar>
			<div className={`${styles.content} ${contentClass}`} style={noPadding ? { padding: 0 } : {}}>
				{children}
			</div>
		</div>
	);
};
