import React from "react";
import ReactDOM from "react-dom/client";
// import { render } from "react-dom";
import "./index.css";
import App from "./main/App";
import reportWebVitals from "./reportWebVitals";
import store from "domain/shared/storage/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

// This change is because we need downgraded React 18 to React 17 to support IM; At first, let test if React 18 can be use.
// const root = document.getElementById("root") as HTMLElement;
// render(<App />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
