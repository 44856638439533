import { configureStore } from "@reduxjs/toolkit";
import userSlice from "domain/shared/storage/user/userSlice";
import shopSlices from "domain/shared/storage/shop/shopSlice";
import versionSlice from "./version/versionSlice";
import IMSlices from "./im/imSlice";

const store = configureStore({
	// middleware: getDefaultMiddleware =>
	//   // serializableCheck这个中间件需要关掉，否则会报错
	//   getDefaultMiddleware({ serializableCheck: false }),
	reducer: {
		user: userSlice,
		selectedShop: shopSlices,
		version: versionSlice,
		IM: IMSlices,
	},
});

export default store;
