import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../typings/store";

export interface IMState {
	account: Account;
	allUnreadCounts: AllUnreadCounts;
	waitingListForGetUnreadCount: Account[];
	userIdToAccount: Record<string, Account | undefined>;
}

const initialState: IMState = {
	account: "user",
	allUnreadCounts: { user: {} },
	waitingListForGetUnreadCount: [],
	userIdToAccount: {},
};

export const IMSlices = createSlice({
	name: "IM",
	initialState,
	reducers: {
		updateAccount: (state, { payload }: PayloadAction<Account>) => {
			state.account = payload;
			state.allUnreadCounts[payload] = state.allUnreadCounts[payload] || {};
		},
		updateUnreadAccounts: (state, { payload }: PayloadAction<CurrentUnreadCount>) => {
			state.allUnreadCounts = {
				...state.allUnreadCounts,
				[state.account]: { ...state.allUnreadCounts[state.account], ...payload },
			};
		},
		updateUserIdWaitingList: (state, { payload }: PayloadAction<Record<string, Account | undefined>>) => {
			state.userIdToAccount = {
				...state.userIdToAccount,
				...payload,
			};
		},
		updateAccountWaitingList: (state, { payload }: PayloadAction<Account[]>) => {
			state.waitingListForGetUnreadCount = payload;
		},
	},
});

export const { updateAccount, updateUnreadAccounts, updateUserIdWaitingList, updateAccountWaitingList } =
	IMSlices.actions;

export const getCurrentUnreadCounts = (state: RootState) => state.IM.allUnreadCounts[state.IM.account];
export const getAccount = (state: RootState) => state.IM.userIdToAccount;

export default IMSlices.reducer;
