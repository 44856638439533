import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import postMsgManager from "domain/shared/postMessage";

export default () => {
	const navigate = useNavigate();

	const msgManager = useMemo(() => {
		return postMsgManager();
	}, []);

	useEffect(() => {
		const onBack = () => {
			const {
				state: { idx },
			} = window.history;
			if (idx) {
				navigate(-1);
			} else {
				msgManager.postMessage("isFirstPage");
			}
		};
		msgManager.on("goBack", onBack);
		return () => {
			msgManager.off("goBack", onBack);
		};
	}, [navigate, msgManager]);
};
