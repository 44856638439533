import { getLocalStorage } from "../localStorage.ts/localStorage";
import { getInstance } from "../singleton";

class PostMsgManager {
	private msgMap: Map<string, CallbackItem[]> = new Map();

	constructor() {
		if (getLocalStorage("platform") === "ios") {
			window.addEventListener("message", (e) => {
				this.exec(e as MessageEvent);
			});
		} else {
			window.document.addEventListener("message", (e) => {
				this.exec(e as MessageEvent);
			});
		}
	}

	private genCbItem(cb: Callback, once = false) {
		return { callback: cb, once };
	}

	private offTargetCallback(key: string, cb: Callback) {
		const cbItems = this.msgMap.get(key) as CallbackItem[];
		this.msgMap.set(
			key,
			cbItems.filter((cbItem) => cbItem.callback !== cb)
		);
	}

	private execCallbackItems(cbItems: CallbackItem[], params: PostMessageParams) {
		for (let i = cbItems.length - 1; i >= 0; i--) {
			const { callback, once } = cbItems[i];
			callback(params);
			if (once) {
				cbItems.splice(i, 1);
			}
		}
	}

	private exec(event: MessageEvent) {
		const msgData: PostMessage = JSON.parse(event.data);
		if (msgData.type && this.msgMap.has(msgData.type)) {
			this.execCallbackItems(this.msgMap.get(msgData.type) as CallbackItem[], msgData.params);
		}
	}

	private addCbItem(key: string, cbItem: CallbackItem) {
		if (this.msgMap.has(key)) {
			this.msgMap.get(key)?.push(cbItem);
		} else {
			this.msgMap.set(key, [cbItem]);
		}
	}

	on(key: string, cb: Callback) {
		const cbItem: CallbackItem = this.genCbItem(cb);
		this.addCbItem(key, cbItem);
	}

	once(key: string, cb: Callback) {
		const cbItem: CallbackItem = this.genCbItem(cb, true);
		this.addCbItem(key, cbItem);
	}

	/**
	 *
	 * @param key
	 * @param cb 传入移除指定响应
	 * @returns
	 */
	off(key: string, cb?: Callback) {
		if (!this.msgMap.has(key)) {
			console.error(`error msg manager key => ${key}`);
			return;
		}
		if (cb) {
			this.offTargetCallback(key, cb);
		} else {
			this.msgMap.delete(key);
		}
	}

	destroy() {
		this.msgMap.clear();
	}

	postMessage<T = Record<string, any>>(key: string, params: T = {} as T) {
		window.postMessage(
			JSON.stringify({
				type: key,
				params,
			}),
			"*"
		);
	}
}

export default () => getInstance("post-msg-manager", () => new PostMsgManager());
