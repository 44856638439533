import { createContext, Dispatch } from "react";

interface NotificationManager {
	count: number;
}

export interface NotificationActionType {
	type: string;
	payload: Partial<NotificationManager>;
}

export const defaultNotificationManager: NotificationManager = {
	count: 0,
};

interface NotificationManagerParams extends NotificationManager {
	dispatch: Dispatch<NotificationActionType>;
}

export function notificationReducer(state: NotificationManager, action: NotificationActionType) {
	switch (action.type) {
		case "updateNotificationCount":
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export const NotificationContext = createContext<NotificationManagerParams>(
	defaultNotificationManager as NotificationManagerParams
);
