import { Suspense, lazy, useCallback, useEffect } from "react";
import ConfigProvider from "antd-mobile/es/components/config-provider";
import useLanguage from "shared/hooks/useLanguage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./page/Layout/Layout";
import NotFound from "page/ErrorPage/NotFound";
import "./App.css";
import { getUserInfo } from "domain/shared/localStorage.ts/getUserInfo";
import getI18nLocal from "domain/shared/commonFun/getI18nLocal";
import "domain/shared/i18n/initI18n";
import { useAppDispatch, useAppSelector } from "domain/shared/storage/storage-hook";
import { getUser, setUserinfo } from "domain/shared/storage/user/userSlice";
import "./App.less";
import { Modal } from "antd-mobile/es/components/modal/modal";
import useCheckVersion from "shared/hooks/useCheckVersion";
import { Navigate } from "react-router";
import postMessage from "domain/shared/postMessage";
import RouteGuard from "shared/components/RouteGuard/RouteGuard";
import Loading from "shared/components/Loading/Loading";
import postMsgManager from "domain/shared/postMessage";
import zoomManager from "domain/shared/commonFun/zoomManager";
import { getLocalStorage } from "domain/shared/localStorage.ts/localStorage";
import useUnreadCounts from "domain/im/useUnreadCounts";
// import request from "domain/shared/communication";
// import { setLocalStorage } from "domain/shared/localStorage.ts/localStorage";

const AppealDetail = lazy(() => import("page/Ticket/Detail/AppealDetail"));
const AppealApply = lazy(() => import("page/Ticket/Apply/AppealApply"));
const ChainOffer = lazy(() => import("page/ChainOffer/ChainOffer"));
const ChainOfferForm = lazy(() => import("page/ChainOfferForm/ChainOfferForm"));
const Setting = lazy(() => import("page/Setting/Setting"));
const Deposit = lazy(() => import("page/Wallet/Deposit/Deposit"));
const Withdraw = lazy(() => import("page/Wallet/Withdraw/Withdraw"));
const OrderPayment = lazy(() => import("page/OrderPayment/OrderPayment"));
const WaitingForPayment = lazy(() => import("page/OrderPayment/components/WaitingForPayment/WaitingForPayment"));
const PaymentIssues = lazy(() => import("page/OrderPayment/components/PaymentIssues/PaymentIssues"));
const WaitingForTransfer = lazy(() => import("page/OrderPayment/components/WaitingForTransfer/WaitingForTransfer"));
const TransferSuccess = lazy(() => import("page/OrderPayment/components/TransferSuccess/TransferSuccess"));
const FiatList = lazy(() => import("page/Fiat/FiatList"));
const Bill = lazy(() => import("page/Bill/Bill"));
const ShopOffer = lazy(() => import("page/ShopOffer/ShopOffer"));
const ShopOfferForm = lazy(() => import("page/ShopOfferForm/ShopOfferForm"));
const User = lazy(() => import("page/User/User"));
const Address = lazy(() => import("page/Address/Address"));
const AddAddress = lazy(() => import("page/Address/AddressEditor"));
const Order = lazy(() => import("page/Order/List/Order"));
const OrderDetail = lazy(() => import("page/Order/Detail/OrderDetail"));
const FiatEditor = lazy(() => import("page/Fiat/Editor/FiatEditor"));
const Notifications = lazy(() => import("page/Notifications/Notifications"));
const Appeal = lazy(() => import("page/Ticket/Appeal"));

const SuspenseComp = ({ comp }: { comp: JSX.Element }) => {
	return <Suspense children={comp} fallback={<Loading />} />;
};

function App() {
	const dispatch = useAppDispatch();
	const { userInfo } = useAppSelector(getUser);
	const { t, keys } = useLanguage();
	const { lastVersion, updateVersionContent, updateModalVisible, onHideUpdateModal } = useCheckVersion();
	useUnreadCounts();

	const init = useCallback(() => {
		zoomManager();
		const userinfo = getUserInfo();
		if (userinfo) {
			dispatch(setUserinfo(userinfo));
		}
		// request
		// 	.postBase("/public/auth/loginViaCode", {
		// 		// email: "merchant@qq.com",
		// 		email: "aaa@qq.com",
		// 		deviceType: "MOBILE",
		// 		verificationCode: "76",
		// 	})
		// 	.then((value) => {
		// 		let userInfo = {
		// 			mail: value.body.email,
		// 			accessToken: value.body.accessToken,
		// 			merchant: value.body.people === "MERCHANT",
		// 		} as User;
		// 		dispatch(setUserinfo(userInfo));
		// 		setLocalStorage("userInfo", userInfo);
		// 	})
		// 	.catch(() => console.log("error"));
	}, [dispatch]);

	useEffect(() => {
		init();
		postMessage().once("init", init);
	}, [init]);

	const { currentLang, initLang } = useLanguage();
	useEffect(() => {
		initLang();
	}, [initLang]);

	return (
		<ConfigProvider locale={getI18nLocal(currentLang)}>
			<div className="app" style={{ paddingTop: `${getLocalStorage("statusBarHeight")}px` }}>
				<BrowserRouter>
					<RouteGuard>
						<Routes>
							<Route Component={Layout}>
								<Route path="offer" element={<SuspenseComp comp={<ChainOffer />} />}></Route>
								<Route path="user" element={<SuspenseComp comp={<User />} />}></Route>
								<Route path="orders" element={<SuspenseComp comp={<Order />} />}></Route>
							</Route>
							{userInfo && (
								<Route
									path="/"
									element={<Navigate to={userInfo.merchant ? "orders" : "offer"} replace />}
								></Route>
							)}
							<Route path="/address" element={<SuspenseComp comp={<Address />} />}></Route>
							<Route
								path="/offer/createOrder"
								element={<SuspenseComp comp={<ChainOfferForm />} />}
							></Route>

							<Route path="order" element={<SuspenseComp comp={<OrderPayment />} />}>
								<Route
									path="orderPayment"
									element={<SuspenseComp comp={<WaitingForPayment />} />}
								></Route>
								<Route path="paymentIssues" element={<SuspenseComp comp={<PaymentIssues />} />}></Route>
								<Route path="transfer" element={<SuspenseComp comp={<WaitingForTransfer />} />}></Route>
								<Route
									path="transferSuccess"
									element={<SuspenseComp comp={<TransferSuccess />} />}
								></Route>
							</Route>

							<Route path="/address/edit" element={<SuspenseComp comp={<AddAddress />} />}></Route>
							<Route
								path="/order/detail/:orderNumber"
								element={<SuspenseComp comp={<OrderDetail />} />}
							></Route>
							<Route path="/appeal" element={<SuspenseComp comp={<Appeal />} />}></Route>
							<Route path="/notification" element={<SuspenseComp comp={<Notifications />} />}></Route>
							<Route
								path="/appeal/detail/:appealId"
								element={<SuspenseComp comp={<AppealDetail />} />}
							></Route>
							<Route path="/appeal/apply" element={<SuspenseComp comp={<AppealApply />} />}></Route>
							<Route path="/deposit" element={<SuspenseComp comp={<Deposit />} />}></Route>
							<Route path="/withdraw" element={<SuspenseComp comp={<Withdraw />} />}></Route>
							<Route path="/setting" element={<SuspenseComp comp={<Setting />} />}></Route>
							<Route path="/fiat" element={<SuspenseComp comp={<FiatList />} />}></Route>
							<Route path="/fiat/edit" element={<SuspenseComp comp={<FiatEditor />} />}></Route>
							<Route path="/bill" element={<SuspenseComp comp={<Bill />} />}></Route>
							<Route path="/shopOffer" element={<SuspenseComp comp={<ShopOffer />} />}></Route>
							<Route path="/shop-offer-form" element={<SuspenseComp comp={<ShopOfferForm />} />}></Route>
							<Route path="*" Component={NotFound}></Route>
						</Routes>
					</RouteGuard>
				</BrowserRouter>
				<Modal
					visible={updateModalVisible}
					showCloseButton
					closeOnMaskClick
					onClose={onHideUpdateModal}
					title={t(keys.NewVersionFound) + lastVersion}
					content={updateVersionContent}
					actions={[
						{
							key: "update",
							text: t(keys.UpdateNow),
							className: "primary-btn",
							style: {
								height: "40px",
								fontSize: "16px",
							},
							onClick() {
								postMsgManager().postMessage("startUpdate");
								onHideUpdateModal();
							},
						},
					]}
					className={"update-modal"}
				/>
			</div>
		</ConfigProvider>
	);
}

export default App;
