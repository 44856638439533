import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../typings/store";
import { getLocalStorage } from "domain/shared/localStorage.ts/localStorage";

export interface VersionState {
	version: string;
	lastVersion: string;
	shouldUpdateVersion: boolean;
	updateVersionContent: string;
	updateModalVisible: boolean;
}

const initialState: VersionState = {
	version: getLocalStorage("app_version") || ("" as string),
	lastVersion: "",
	shouldUpdateVersion: false,
	updateVersionContent: "",
	updateModalVisible: false,
};

export const versionSlices = createSlice({
	name: "version",
	initialState,
	reducers: {
		setVersionState: (
			state,
			{ payload }: PayloadAction<Omit<VersionState, "version" | "shouldUpdateVersion" | "updateModalVisible">>
		) => {
			state.lastVersion = payload.lastVersion;
			state.updateVersionContent = payload.updateVersionContent;
			state.shouldUpdateVersion = payload.lastVersion !== state.version;
		},

		setUpdateVersionModalVisible: (state, { payload }: PayloadAction<boolean>) => {
			state.updateModalVisible = payload;
		},
	},
});

export const { setVersionState, setUpdateVersionModalVisible } = versionSlices.actions;

export const getVersionInfo = (state: RootState) => state.version;

export default versionSlices.reducer;
