import zh_cn from "antd-mobile/es/locales/zh-CN";
import en_us from "antd-mobile/es/locales/en-US";
import { Locale } from "antd-mobile/es/locales/base";

export default (prop: string): Locale => {
  let language: Locale;
  if (prop === "zh_CN") {
    language = zh_cn;
  } else if (prop === "en") {
    language = en_us;
  } else {
    language = en_us;
  }
  return language;
};
