import postMsgManager from "domain/shared/postMessage";
import { updateUnreadAccounts } from "domain/shared/storage/im/imSlice";
import { useAppDispatch } from "domain/shared/storage/storage-hook";
import { useEffect } from "react";

interface UnreadCountFromMsg {
	account: Account;
	count: UnreadCount;
}

export default () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		postMsgManager().on("unreadCountUpdate", ({ data }) => {
			try {
				const unreadCounts: CurrentUnreadCount = {};

				console.log("unreadCountUpdate", data);

				data.forEach((unreadCountFromMsg: UnreadCountFromMsg) => {
					unreadCounts[unreadCountFromMsg.account] = unreadCountFromMsg.count;
				});

				dispatch(updateUnreadAccounts(unreadCounts));
			} catch (error) {
				return;
			}
		});

		return () => {
			postMsgManager().off("unreadCountUpdate");
		};
	}, [dispatch]);
};
