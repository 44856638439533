import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../typings/store";

export interface UserState {
	userInfo?: User;
}

const initialState: UserState = {};

export const userSlices = createSlice({
	name: "userinfo",
	initialState,
	reducers: {
		setUserinfo: (state, { payload }: PayloadAction<User>) => {
			state.userInfo = payload;
		},
		deleteUserinfo: (state, _: PayloadAction) => {
			state.userInfo = undefined;
		},
	},
});

export const { setUserinfo, deleteUserinfo } = userSlices.actions;

export const getUser = (state: RootState) => state.user;

export default userSlices.reducer;
