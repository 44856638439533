import { getLocalStorage } from "./localStorage";

export const getUserInfo = (): User | undefined => {
	const str = getLocalStorage("userInfo");
	try {
		return str ? JSON.parse(str) : "";
	} catch (error) {
		return;
	}
};
