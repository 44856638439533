import styles from "./Layout.less";
import Content from "./Content/Content";
import Navigation from "./Navigation/Navigation";
import { useEffect, useReducer } from "react";
import {
	notificationReducer,
	defaultNotificationManager,
	NotificationContext,
} from "shared/storage/notificationStorage";
import { getLocalStorage } from "domain/shared/localStorage.ts/localStorage";

export default () => {
	const [state, dispatch] = useReducer(notificationReducer, defaultNotificationManager);

	useEffect(() => {
		dispatch({
			type: "updateNotificationCount",
			payload: { count: 10 },
		});
	}, [dispatch]);

	return (
		<div
			className={styles.layout}
			style={{ height: `calc(100vh - ${getLocalStorage("statusBarHeight") || 30}px)` }}
		>
			<NotificationContext.Provider value={{ ...state, dispatch }}>
				<Content />
			</NotificationContext.Provider>
			<Navigation></Navigation>
		</div>
	);
};
