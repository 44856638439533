import postMsgManager from "domain/shared/postMessage";
import { useAppDispatch, useAppSelector } from "domain/shared/storage/storage-hook";
import {
	getVersionInfo,
	setUpdateVersionModalVisible,
	setVersionState,
} from "domain/shared/storage/version/versionSlice";
import { useCallback, useEffect } from "react";

export default () => {
	const { lastVersion, shouldUpdateVersion, updateVersionContent, updateModalVisible } =
		useAppSelector(getVersionInfo);

	const dispatch = useAppDispatch();

	const onHideUpdateModal = useCallback(() => {
		dispatch(setUpdateVersionModalVisible(false));
	}, [dispatch]);

	useEffect(() => {
		postMsgManager().postMessage("checkUpdate");
	}, []);

	// TODO waiting for apk info update
	const onVersionInfo: Callback = useCallback(
		({ versionInfo } = {}) => {
			const versionInfoArr: string[] = versionInfo.trim().split("\n");

			const lastVersion = versionInfoArr.find((item) => item.indexOf("version") > -1)?.split(": ")[1];
			const updateVersionContent = versionInfoArr.find((item) => item.indexOf("updateInfo") > -1)?.split(":")[1] || "Update with better performance.";

			lastVersion &&
				dispatch(
					setVersionState({
						lastVersion,
						updateVersionContent,
					})
				);
		},
		[dispatch]
	);

	useEffect(() => {
		postMsgManager().on("versionInfo", onVersionInfo);

		return () => {
			postMsgManager().off("versionInfo");
		};
	}, [onVersionInfo]);

	useEffect(() => {
		if (shouldUpdateVersion) {
			dispatch(setUpdateVersionModalVisible(true));
		}
	}, [shouldUpdateVersion, dispatch]);

	return {
		lastVersion,
		updateVersionContent,
		updateModalVisible,
		onHideUpdateModal,
	};
};
