import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../typings/store";
import { Shop } from "typings/Shop";
import postMessage from "domain/shared/postMessage";

export interface SelectedShopState {
	selectedShop?: Shop;
}

const initialState: SelectedShopState = {};

export const shopSlices = createSlice({
	name: "selectedShop",
	initialState,
	reducers: {
		setSelectedShop: (state, { payload }: PayloadAction<Shop>) => {
			postMessage().postMessage("switchShop", { shopId: payload.uuid });
			state.selectedShop = payload;
		},
		deleteSelectedShop: (state, _: PayloadAction) => {
			state.selectedShop = undefined;
		},
	},
});

export const {setSelectedShop, deleteSelectedShop} = shopSlices.actions;

export const getSelectedShop = (state: RootState) => state.selectedShop;

export default shopSlices.reducer;
