export const zh_CN = {
  ContinueWithGoogle: "通过Google登录",
  ContinueWithEmail: "邮箱登录",
  TapToAgree: "点击登录表明同意",
  TapToTermsOfService: "服务协议",
  And: "和",
  TapToPrivatePolicy: "隐私政策",
  InputEmail: "输入邮箱注册或登录",
  EmailAddress: "请输入邮箱",
  VerificationCode: "请输入验证码",
  SendVerificationCode: "获取验证码",
  ResendVerificationCode: "重发",
  LogIn: "登录",
  InvalidEmail: "请输入合法的邮箱地址",
  InvalidVerificationCode: "请正确输入验证码",
  VerificationCodeExpired: "验证码已过期",
  Offers: "出价",
  Order: "订单",
  Profile: "我的",
  Network: "网络",
  Amount: "金额",
  All: "全部",
  PaymentMethod: "付款方式",
  SortBy: "排序",
  PriceASC: "价格从低到高",
  LimitDESC: "限额从高到低",
  Reset: "重置",
  Confirm: "确定",
  PayOrCancel: "您有待支付的订单，请先支付或取消",
  ViewOrder: "查看订单",
  Terms: "交易条款",
  Merchant: "商家",
  ReadBeforeOrder: "请在下单前仔细阅读",
  Price: "单价",
  IWillPay: "我将支付",
  IWillReceive: "我将收到",
  MyReceivingAddress: "我的收币地址",
  InputMatchedAddress: "请输入与网络匹配的地址",
  PaymentTimeLimit: "付款时限",
  Next: "下一步",
  Margin: "保证金",
  KYCVerified: "KYC 认证",
  EmailVerified: "Email 认证",
  SMSVerified: "SMS 认证",
  SelectPaymentMethod: "选择付款方式",
  ReceivingAddressBook: "收币地址簿",
  Add: "添加",
  Min: "最小",
  Max: "最大",
  AddressNotMatchNetwork: "地址与网络不匹配，请检查",
  ContactMerchant: "联系商家",
  PaymentAmount: "支付金额",
  Quantity: "数量",
  SafetyReminder: "安全提示",
  BeforePayNeedScreenshot: "付款前请联系商家提供交易条款要求的证明截图",
  AfterPayNeedClick: "付款完成后，请务必点击【我已付款，通知商家】按钮，否则将导致订单超时并被取消，您的资产可能丢失",
  PayCountdown: "请在 ∬ 内付款给商家",
  HavingTrouble: "遇到问题",
  PaidAndNotifiy: "我已付款，通知商家",
  OrderNotPay: "您已成功下单，请尽快付款",
  TroubleWithPayment: "付款遇到问题",
  NotWantToTransfer: "不想交易了",
  CancelOrderNotify: "请点击底部【取消订单】取消当前交易；每日最多可取消3次，超过后将限制交易",
  PayFailed: "向商家提供的收款方式付款，但支付失败",
  AskForOtherPayment: "请联系商家咨询其他付款方式",
  CancelOrder: "取消订单",
  Warning: "注意",
  PaidNotification: "如果您已付款给商家，请勿取消订单",
  CancelOrderTip: "每日最多可取消3次，包含主动取消订单和超时未支付导致订单取消，超过3次后将限制当日交易",
  TransactionRestrictionsTip: "若您由于商家不回复消息而取消订单导致被限制交易，请联系客服处理",
  DoubleConfirmInCancel: "我没有付款给商家 / 商家已退款给我",
  PaymentMethodUpdated: "检测到收款方式已更新，请立即刷新",
  Refresh: "刷新",
  WaitingForMerchantCheckReceive: "等待商家确认放行",
  TransferToYourWallet: "转账至您的钱包",
  TransferredToYourWallet: "已划转至您的钱包",
  ViewOtherOffers: "查看其他出价",
  Appeal: "申诉",
  Reason: "申诉理由",
  PleaseSelect: "请选择",
  Description: "描述",
  PleaseInput: "请输入",
  UploadEvidence: "上传证据",
  Submit: "提交",
  AppealReason: "申诉理由",
  PaidNotReceiveCoins: "我已付款，但未收到币",
  Others: "其他",
  ReceiveButAmountNotRight: "我已收到付款，但金额不正确",
  ReceiveButNotSecure: "我已收到付款，但买家资金不安全",
  NotReceiveFiat: "买家将订单标记为已付款，但我未收到款项",
  ExPalNotification: "ExPal通知",
  Pending: "进行中",
  Completed: "已结束",
  Released: "已放行，划转中",
  Received: "到账数量",
  WaitingForRelease: "等待商家放行",
  Pay: "去付款",
  ReleaseTimeout: "超时未放行",
  Cancelled: "已取消",
  Successful: "已完成",
  OrderNumber: "订单号",
  CreationTime: "创建时间",
  PaidWaitingForRelease: "已付款，等待商家放行",
  MerchantReleased: "商家已放行，划转中",
  TransactionSuccessful: "划转到账",
  CancellationReason: "取消原因",
  BuyerCancelled: "买家取消",
  MerchantCancelled: "商家取消",
  PlatformCancelled: "平台取消",
  ReleaseTimeoutTip: "超时未放行，请联系商家",
  NoData: "暂无数据",
  TotalPurchases: "累计购买",
  MyAppeals: "我的申诉",
  HelpCenter: "帮助中心",
  ConfirmToRemoveAddress: "确认移除地址",
  Cancel: "取消",
  RemovedSuccessfully: "移除成功",
  AddAddress: "添加地址",
  SelectNetwork: "选择网络",
  Address: "地址",
  Alias: "别名（选填）",
  CharactersLimit32Tip: "请输入 1~32 字符",
  EditAlias: "编辑别名",
  Saved: "已保存",
  UnderAppeal: "申诉中",
  AppealDetails: "申诉详情",
  Resolution: "处理结果",
  AppealCancelled: "取消申诉",
  AppealProcessing: "处理中，请等待",
  Language: "语言",
  CheckForUpdates: "检查更新",
  AlreadyOnLatestVersion: "当前为最新版本",
  PrivatePolicy: "服务协议、隐私政策",
  LogOut: "退出登录",
  NewVersionFound: "发现新版本",
  UpdateNow: "立即更新",
  Inquiries: "咨询",
  BuyerFAQ: "买家常见问题",
  MerchantFAQ: "商家常见问题",
  YourName: "您的称呼",
  Message: "留言",
  SubmitSuccessfully: "提交成功",
  AppealRespondTip: "平台将在2个工作日内通过系统通知回复您",
  OrderDetails: "订单详情",
  ReceivingAddress: "收币地址",
  Switch: "切换",
  ConfirmRelease: "确认放行",
  ConfirmAsset: "确认放行资产",
  CheckReceiveAccount: "请查看收款账户（如银行卡或电子钱包）后确认款项已入账您的【可用余额】",
  EnsureToReceivePayment: "我确认已成功收到款项，并且金额和收款方式正确",
  WaitPayment: "Waiting for Buyer's Payment",
  WaitingPromptly: "买家已付款，请尽快放行",
  ReleaseAddress: "放行地址",
  EnsureAddress: "请确认放行地址中包含本次划转的地址，否则将无法监控到账",
  SwitchShop: "切换店铺",
  TransFeeBalance: "手续费钱包余额",
  Bill: "账单",
  TotalSales: "累计卖出",
  OffersManagement: "出价管理",
  PaymentMethodManagement: "收款方式管理",
  ReleaseAddressBook: "放行地址簿",
  TransactionFee: "手续费",
  CumulativeDeposit: "累计充值到账",
  CumulativeWithdrawal: "累计提现到账",
  AllTypes: "全部类型",
  Deposit: "充值",
  Withdrawal: "提现",
  UntilToday: "历史至今",
  Recent30Days: "近30日",
  InsuffcientBalanceWarn: "余额不足，请尽快充值",
  ArrearsWarn: "已欠费，请尽快充值",
  ReceiveChannel: "收款渠道",
  SelectReceiveChannel: "选择收款渠道",
  ReceiveNotes: "收款信息",
  PleaseInputPaymentDetails: "请填写收款账号、收款人姓名等收款信息",
  Customize: "自定义",
  ReceiveChannelPlaceholder: "请输渠道名称",
  AddSuccess: "添加成功",
  Save: "保存",
  SaveSuccess: "保存成功",
  Deleted: "已删除",
  CurrentPaymentHasBeenReferenced: "当前收款信息已被引用，不支持删除",
  AddPaymentMethod: "添加收款方式",
  EditPaymentMethod: "编辑收款方式",
  EditPaymentMethodWarn: "保存后，已发布的出价中该收款方式将同步更新",
  Published: "已发布",
  Unpublished: "待发布",
  SingleOrderLimitRange: "单笔订单限额范围",
  SelectPaymentMethodToReceive: "选择收款方式",
  Select: "选择",
  Delete: "删除出价",
  SelectPaymentMethodMaxCountWarn: "最多可选择 6 种收款方式",
  SelectPaymentMethodDuplicatedWarn: "收款方式不允许重复，请修改",
  SelectPaymentMethodMinCountWarn: "请设置至少1个网络的单笔订单限额",
  DeleteOfferFailWarn: "当前出价已发布，不支持删除，请先在列表处取消发布",
  CheckSelectedNetwork: "安全提示：请务必确认已选网络",
  ScanQRCodeOrCopyWalletAddressTOTransfer: "使用钱包APP扫码或复制下方钱包地址完成转账",
  WithDrawAll: "全部提出",
  WithDrawAmount: "提现金额",
  ExpectedReceivedAmount: "预计到账金额",
  InputWalletAddress: "输入钱包地址",
  ReleaseAddressMaxCountWarn: "每个网络最多可添加5个放行地址",
  TheMaxLimitForSingleOrderInfo: "[保证金] 、[Expal手续费钱包余额 × 100]、[商家自定义最大限额]，三者中取最小为实时单笔订单最大限额",
  BeforeOrderInfo: "用户下单前，系统将验证所填购买金额是否符合本出价的限额要求",
  RemoveWarn: "移除后将不再监控该地址的放行划转状态",
  Removed: "已移除",
  AddReleaseAddress: "添加地址",
  PleaseInputCharacters: "请输入 1~32 字符",
  WaitingForBuyerPayment: "等待买家付款",
  MerchantReleasedTip: "商家已确认放行",
  UrgeToTransfer: "请尽快完成划转",
  copied: "已复制",
  FaitChannel: "收款方式",
  LimitAmount: "限额",
  GasFeeTip: "Gas Fee是系统估算出的确保完成划转最少需要消耗的链上代币数量后按照实时币价换成成USDT的数量；完成划转后真实消耗的Gas Fee请在链上查询",
  WithdrawGasFeeTip: "Gas费由提现金额支付",
  Failed: "失败",
  reapplyForWithdraw: "请重新申请提现",
  Withdrawing: "提现中",
  NewOffer: "创建出价",
  More: "更多",
  EditOffer: "编辑出价",
  Common: "常用渠道",
  Settings: "设置",
  SelectLanguage: "选择语言",
  ViewAll: "查看全部",
  TransactionInformation: "填写交易信息",
  OrderGenerated: "订单已生成",
  proof: "证据",
  balance: "余额",
  sendSuccessfully: "发送成功",
  codeSent: "验证码已发送，请填写",
  deletePaymentMethod: "确认删除收款方式",
  deleteOffer: "确认删除出价",
  PaymentMethodShort: "付款方式",
  Payment: "付款",
  UnknownError: "未知错误。寻求帮助请联系邮箱 Service@ASKCHAIN.io",
  Confirmed: "已确认",
  NotifiedTheMerchant: "已通知商家",
  Buyer: "买家",
  ContactBuyer: "联系买家",
};
