import { keys } from "domain/shared/i18n/languages/keys";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
      window.localStorage.setItem("language", lang);
    },
    [i18n]
  );

  const initLang = useCallback(() => {
    const lang = window.localStorage.getItem("language") || "en";

    changeLanguage(lang);
  }, [changeLanguage]);

  const twv = useCallback(
    (key: keyof typeof keys, variable: string) => {
      try {
        return t(keys[key]).replace("∬", variable);
      } catch (error) {
        return "";
      }
    },
    [t]
  );

  return {
    currentLang: i18n.language,
    keys,
    t,
    initLang,
    changeLanguage,
    twv,
  };
};
