const getLocalStorage = (key: string) => {
	return window.localStorage.getItem(key);
};

const setLocalStorage = (key: string, value: any) => {
	window.localStorage.setItem(key, JSON.stringify(value));
};

const removeLocalStorage = (key: string) => {
	window.localStorage.removeItem(key);
};

export { getLocalStorage, setLocalStorage, removeLocalStorage };
