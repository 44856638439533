import { getInstance } from "../singleton";

class ZoomManager {
	lastTouchEnd = 0;

	constructor() {
		this.init();
	}

	private onTouchStart(event: TouchEvent) {
		if (event.touches.length > 1) {
			event.preventDefault();
		}
	}

	private onTouched(event: TouchEvent) {
		const now = Date.now();
		if (now - this.lastTouchEnd <= 300) {
			event.preventDefault();
		}
	}

	private init() {
		document.addEventListener("touchstart", this.onTouchStart);
		document.addEventListener("touchend", this.onTouched);
	}

	destroy() {
		document.removeEventListener("touchstart", this.onTouchStart);
		document.removeEventListener("touchend", this.onTouched);
	}
}

export default () => {
	getInstance("zoom-manager", () => new ZoomManager());
};
