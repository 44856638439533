import { useLocation, useNavigate } from "react-router-dom";
import styles from "../Layout.less";
import { ReactComponent as OfferIcon } from "shared/assets/icon/offer.svg";
import { ReactComponent as OrderIcon } from "shared/assets/icon/order.svg";
import { ReactComponent as MyIcon } from "shared/assets/icon/my.svg";
import useLanguage from "shared/hooks/useLanguage";
import { useAppSelector } from "domain/shared/storage/storage-hook";
import { getUser } from "domain/shared/storage/user/userSlice";

const getTargetClass = (pathname: string, targetRoute: string) =>
	pathname.indexOf(targetRoute) > -1 ? styles.activity : styles.normal;

export default () => {
	const { pathname } = useLocation();
	const { userInfo } = useAppSelector(getUser);
	const navigate = useNavigate();
	const { t, keys } = useLanguage();

	return (
		<div className={styles.navigation}>
			{!userInfo?.merchant && (
				<div className={getTargetClass(pathname, "offer")} onClick={() => navigate("offer")}>
					<OfferIcon className="svg" />
					{t(keys.Offers)}
				</div>
			)}
			<div className={getTargetClass(pathname, "orders")} onClick={() => navigate("orders")}>
				<OrderIcon className="svg" />
				{t(keys.Order)}
			</div>
			<div className={getTargetClass(pathname, "user")} onClick={() => navigate("user")}>
				<MyIcon className="svg" />
				{t(keys.Profile)}
			</div>
		</div>
	);
};
