export const en = {
  ContinueWithGoogle: "Continue With Google",
  ContinueWithEmail: "Continue With Email",
  TapToAgree: "By tapping Continue, you agree to our",
  TapToTermsOfService: "Terms of Service",
  And: "and",
  TapToPrivatePolicy: "Private Policy",
  InputEmail: "Input your Email to sign up or log in",
  EmailAddress: "Email Address",
  VerificationCode: "Verification Code",
  SendVerificationCode: "Send",
  ResendVerificationCode: "Resend",
  LogIn: "Log in",
  InvalidEmail: "Please input a valid email address",
  InvalidVerificationCode: "Please input a correct verification code",
  VerificationCodeExpired: "This verification code has expired",
  Offers: "Offers",
  Order: "Order",
  Profile: "Profile",
  Network: "Network",
  Amount: "Amount",
  All: "All",
  PaymentMethod: "Payment Method",
  SortBy: "Sort By",
  PriceASC: "Prices from Low to High",
  LimitDESC: "Limits from High to Low",
  Reset: "Reset",
  Confirm: "Confirm",
  PayOrCancel: "You have pending orders to pay, please pay or cancel first",
  ViewOrder: "View Order",
  Terms: "Terms",
  Merchant: "Merchant",
  ReadBeforeOrder: "Please read carefully before placing an order",
  Price: "Price",
  IWillPay: "I Will Pay",
  IWillReceive: "I Will Receive",
  MyReceivingAddress: "My Receiving Wallet Address",
  InputMatchedAddress: "Please input a wallet address that matches the network",
  PaymentTimeLimit: "Payment Time Limit",
  Next: "Next",
  Margin: "Margin",
  KYCVerified: "KYC Verified",
  EmailVerified: "Email Verified",
  SMSVerified: "SMS Verified",
  SelectPaymentMethod: "Select Payment Method",
  ReceivingAddressBook: "Receiving Address Book",
  Add: "Add",
  Min: "Min",
  Max: "Max",
  AddressNotMatchNetwork: "Address does not match the network, please check",
  ContactMerchant: "Contact Merchant",
  PaymentAmount: "Payment Amount",
  Quantity: "Quantity",
  SafetyReminder: "Safety Reminder",
  BeforePayNeedScreenshot: "Before making payment, please contact the merchant to provide a screenshot of the proof required for the transaction terms",
  AfterPayNeedClick: "After completing the payment, be sure to click the 'I have paid, notify the merchant' button, otherwise the order may time out and be canceled, leading to potential loss of your assets",
  PayCountdown: "Please make payment to the merchant within",
  HavingTrouble: "Having Trouble",
  PaidAndNotifiy: "I have paid, notify the merchant",
  OrderNotPay: "You have successfully placed an order, Please make payment as soon as possible",
  TroubleWithPayment: "Having trouble with payment",
  NotWantToTransfer: "Don't want to proceed transaction anymore",
  CancelOrderNotify: "Please click on the bottom 'Cancel Order' to cancel the current transaction, a maximum of 3 cancellations is allowed per day, exceeding this limit will result in restricted transactions",
  PayFailed: "Payment to the merchant's provided payment method, but the payment failed",
  AskForOtherPayment: "Please contact the merchant to inquire about alternative payment methods",
  CancelOrder: "Cancel Order",
  Warning: "Attention",
  PaidNotification: "If you have already made payment to the merchant, please do not cancel the order",
  CancelOrderTip: "You can cancel up to 3 times per day, including voluntary cancellations and cancellations due to timeouts, Exceeding this limit will restrict transactions for the day",
  TransactionRestrictionsTip: "If you are restricted from transaction due to cancelling the order because the merchant did not reply to messages, please contact customer service for assistance",
  DoubleConfirmInCancel: "I have not paid the merchant / The merchant has refunded me",
  PaymentMethodUpdated: "Payment method detected as updated, please refresh immediately",
  Refresh: "Refresh",
  WaitingForMerchantCheckReceive: "Waiting for merchant confirmation of release",
  TransferToYourWallet: "Transfer to Your Wallet",
  TransferredToYourWallet: "Transferred to Your Wallet",
  ViewOtherOffers: "View Other Offers",
  Appeal: "Appeal",
  Reason: "Reason",
  PleaseSelect: "Please select",
  Description: "Description",
  PleaseInput: "Please input",
  UploadEvidence: "Upload Proof",
  Submit: "Submit",
  AppealReason: "Appeal Reason",
  PaidNotReceiveCoins: "I have paid，but I did not receive the coins",
  Others: "Others",
  ReceiveButAmountNotRight: "I have received the payment, but the amount is incorrect",
  ReceiveButNotSecure: "I have received the payment, but the buyer's funds are not safe",
  NotReceiveFiat: "The buyer has marked the order as paid, but I have not received the payment",
  ExPalNotification: "ExPal Notification",
  Pending: "Pending",
  Completed: "Completed",
  Released: "Released, transferring ",
  Received: "Received",
  WaitingForRelease: "Waiting for Release",
  Pay: "Pay",
  ReleaseTimeout: "Release Timeout",
  Cancelled: "Cancelled",
  Successful: "Successful",
  OrderNumber: "Order Number",
  CreationTime: "Creation Time",
  PaidWaitingForRelease: "Buyer Paid, waiting for Merchant's Release",
  MerchantReleased: "Merchant Released, transaction in Progress",
  TransactionSuccessful: "Transaction successful",
  CancellationReason: "Reason for Cancellation",
  BuyerCancelled: "Buyer Cancelled",
  MerchantCancelled: "Merchant Cancelled",
  PlatformCancelled: "Platform Cancelled",
  ReleaseTimeoutTip: "Release Timeout, Please Contact the Merchant",
  NoData: "No data",
  TotalPurchases: "Total Purchases",
  MyAppeals: "My Appeals",
  HelpCenter: "Help Center",
  ConfirmToRemoveAddress: "Confirm to remove the address",
  Cancel: "Cancel",
  RemovedSuccessfully: "Removed successfully",
  AddAddress: "Add Address",
  SelectNetwork: "Select Network",
  Address: "Address",
  Alias: "Alias(Optional)",
  CharactersLimit32Tip: "Please input 1-32 characters",
  EditAlias: "Edit Alias",
  Saved: "Saved",
  UnderAppeal: "Under Appeal",
  AppealDetails: "Appeal Details",
  Resolution: "Resolution",
  AppealCancelled: "Appeal Cancelled",
  AppealProcessing: "Processing, please wait",
  Language: "Language",
  CheckForUpdates: "Check For Updates",
  AlreadyOnLatestVersion: "Already on the latest version",
  PrivatePolicy: "Terms of Service and Private Policy",
  LogOut: "Log Out",
  NewVersionFound: "New Version Found",
  UpdateNow: "Update Now",
  Inquiries: "Inquiries",
  BuyerFAQ: "Buyer FAQ",
  MerchantFAQ: "Merchant FAQ",
  YourName: "Your Name",
  Message: "Message",
  SubmitSuccessfully: "Submit Successfully",
  AppealRespondTip: "ExPal will respond to you via system notification within 2 work days",
  OrderDetails: "Order Details",
  ReceivingAddress: "Receiving Address",
  Switch: "Switch",
  ConfirmRelease: "Confirm Release",
  ConfirmAsset: "Confirm Release Asset",
  CheckReceiveAccount: "Please check your receiving account (such as a bank card or e-wallet) to confirm that the funds have been credited to your 'Available Balance'",
  EnsureToReceivePayment: "I confirm that I have successfully received the payment, and the amount and payment method are correct",
  WaitPayment: "Waiting for Buyer's Payment",
  WaitingPromptly: "Buyer Paid, Please Release Promptly",
  ReleaseAddress: "Release Address",
  EnsureAddress: "Please ensure that the release address contains the address for this Transaction; otherwise, the deposit may not be monitored",
  SwitchShop: "Switch Shop",
  TransFeeBalance: "Transaction Fee Wallet Balance",
  Bill: "Bill",
  TotalSales: "Total Sales",
  OffersManagement: "Offers Management",
  PaymentMethodManagement: "Payment Method Management",
  ReleaseAddressBook: "Release Address Book",
  TransactionFee: "Transaction Fee",
  CumulativeDeposit: "Cumulative Deposit",
  CumulativeWithdrawal: "Cumulative Withdrawal",
  AllTypes: "All Types",
  Deposit: "Deposit",
  Withdrawal: "Withdrawal",
  UntilToday: "Until Today",
  Recent30Days: "Recent 30 days",
  InsuffcientBalanceWarn: "Insufficient balance, please deposit ASAP",
  ArrearsWarn: "Already overdue, please recharge as soon as possible",
  ReceiveChannel: "Channel",
  SelectReceiveChannel: "Select Channel",
  ReceiveNotes: "Notes",
  PleaseInputPaymentDetails: "Please input payment details such as account number and recipient's name",
  Customize: "Customize",
  ReceiveChannelPlaceholder: "Please input the channel name",
  AddSuccess: "Added Successfully",
  Save: "Save",
  SaveSuccess: "Saved Successfully",
  Deleted: "Deleted",
  CurrentPaymentHasBeenReferenced: "The current payment information has been referenced and cannot be deleted",
  AddPaymentMethod: "Add Payment Method",
  EditPaymentMethod: "Edit Payment Method",
  EditPaymentMethodWarn: "After saving, this payment method will be synchronously updated in the published offers",
  Published: "Published",
  Unpublished: "Unpublished",
  SingleOrderLimitRange: "Single Order Limit Range",
  SelectPaymentMethodToReceive: "Select Payment Method",
  Select: "Select",
  Delete: "Delete",
  SelectPaymentMethodMaxCountWarn: "Up to 6 payment methods can be selected",
  SelectPaymentMethodDuplicatedWarn: "Payment methods cannot be duplicated. Please make changes",
  SelectPaymentMethodMinCountWarn: "Please set single order limit range on at least one network",
  DeleteOfferFailWarn: "The current offer has been published and cannot be deleted, please first cancel the publication in the list",
  CheckSelectedNetwork: "Security Tip: Please confirm the selected network",
  ScanQRCodeOrCopyWalletAddressTOTransfer: "Use wallet App to scan QR code or copy wallet address below to complete transfer",
  WithDrawAll: "All",
  WithDrawAmount: "Withdraw Amount",
  ExpectedReceivedAmount: "Expected Received Amount",
  InputWalletAddress: "Input Wallet Address",
  ReleaseAddressMaxCountWarn: "Up to 5 release addresses can be added for each network",
  TheMaxLimitForSingleOrderInfo: "[Deposit], [Expal fee wallet balance × 100], [Merchant's custom maximum limit], the smallest of these three will be taken as the real-time maximum limit per order",
  BeforeOrderInfo: "Before users place an order, the system will verify whether the entered purchase amount complies with the limit requirements set in this offer",
  RemoveWarn: "Transaction status of this address will no longer be monitored after removal",
  Removed: "Removed",
  AddReleaseAddress: "Add Release Address",
  PleaseInputCharacters: "Please input 1-32 characters",
  WaitingForBuyerPayment: "Waiting for Buyer's Payment",
  MerchantReleasedTip: "Merchant Released",
  UrgeToTransfer: "Please complete the transfer as soon as possible",
  copied: "Copied",
  FaitChannel: "Payment Method",
  LimitAmount: "Limit",
  GasFeeTip: "Gas Fee is the minimum amount of on-chain tokens estimated by the system to ensure the completion of the transfer, converted into the equivalent USDT amount at the real-time coin price \nThe actual Gas Fee consumed after the transfer is completed can be checked on the blockchain \nThe USDT you received has been deducted for Gas Fee",
  WithdrawGasFeeTip: "Gas Fee will be paid from the withdraw amount",
  Failed: "Failed",
  reapplyForWithdraw: "Please reapply for withdraw",
  Withdrawing: "Withdrawing",
  NewOffer: "New Offer",
  More: "More",
  EditOffer: "Edit Offer",
  Common: "Common",
  Settings: "Settings",
  SelectLanguage: "Select Language",
  ViewAll: "View All",
  TransactionInformation: "Transaction Information",
  OrderGenerated: "Order Generated",
  proof: "Proof",
  balance: "Balance",
  sendSuccessfully: "Send Successfully",
  codeSent: "Verification code sent, please input",
  deletePaymentMethod: "Confirm to delete the payment method",
  deleteOffer: "Confirm to delete the offer",
  PaymentMethodShort: "Method",
  Payment: "Payment",
  UnknownError: "Unknown Error. For help, Contact us by Email Service@ASKCHAIN.io",
  Confirmed: "Confirmed",
  NotifiedTheMerchant: "Notified the merchant",
  Buyer: "Buyer",
  ContactBuyer: "Contact Buyer",
};
